@import "../themes/variables";

.admin-page {
    .content {
        padding-top: 3rem;
        display: flex;
        flex-flow: nowrap column;
        align-items: center;

        gap: 2rem;

        margin-bottom: 2rem;
    }

    .title-component{
        p{
            margin-bottom: 0;
        }
    }

    .title-admin{
        display: flex;
        flex-flow: wrap row;
        align-items: center;
        justify-content: space-between;

        gap: 1rem;

    }

    &>.content>div {
        @include section;
        width: 100%;

        margin: 0;
    }

    .card-project-admin {
        display: flex;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: stretch;

        background-color: rgba($color: #000000, $alpha: .03);
        border: 1px solid rgba($color: #000000, $alpha: .1);


        &>img {
            width: 100%;
            height: 10rem;
            max-height: 10rem;
            object-fit: cover;
            border-radius: $radius !important;
            -webkit-border-radius: $radius !important;
        }

        &>div {
            width: 100%;
            height: 100%;
            padding: 1rem;
            display: flex;
            flex-flow: nowrap column;
            align-items: stretch;
            justify-content: space-between;

            &>div {
                &:first-child {
                    display: flex;
                    flex-flow: nowrap column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                &:last-child {
                    display: flex;
                    flex-flow: nowrap row;
                    align-items: center;
                    justify-content: flex-end;
                }
            }
        }

        border-radius: $radius;
    }

    .projects {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        span {
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: .25rem;
        }

        p {
            font-size: .9rem;
            font-weight: 300;
            margin-bottom: .5rem;
        }

        @media screen and (max-width: 1000px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

.edit-project-admin {
    .images-project-admin-mod {
        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;

        .img-cover {
            width: 7rem;
            max-width: 7rem;
            height: 7rem;

            display: flex;
            align-items: flex-start;

            background-color: rgba($color: #000000, $alpha: .03);
            border-radius: $radius;

            &>img {
                max-height: 7rem;
                max-width: 7rem;
                min-height: 7rem;
                min-width: 7rem;
                height: 7rem;
                width: 7rem;
                object-fit: cover;

                border-radius: $radius;
            }

            &>div {
                position: relative;
                width: 2rem;
                height: 2rem;

                margin-left: -2rem;

                display: block;

                z-index: 10;

                background-color: rgba($color: #fff, $alpha: .7);
                box-shadow: $shadow;

                cursor: pointer;

                input {


                    max-width: 2rem;
                    max-height: 2rem;

                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    opacity: 0;
                    z-index: 100;
                }

                i {
                    color: black;
                    position: absolute;
                    width: 2rem;
                    height: 2rem;
                    min-width: 2rem;
                    min-height: 2rem;
                    max-width: 2rem;
                    max-height: 2rem;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.5rem;

                    z-index: 10;
                }

            }

            &.add-image-card {
                position: relative;

                &>i {
                    width: inherit;
                    height: inherit;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 4rem;
                }

                input {
                    cursor: pointer;

                    width: inherit;
                    height: inherit;

                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;

                    opacity: 0;
                }
            }
        }
    }

    .images-project-admin-mod {
        max-height: 35rem;
        overflow-y: auto;
    }

    .cards-manager-admin-images {
        display: flex;
        flex-flow: wrap row;
        align-items: stretch;
        justify-content: flex-start;

        gap: 1rem;

        &>div {
            flex: 1 1 calc(20% - 1rem);


            .add-image-button {
                button {
                    width: inherit;
                    height: inherit;
                }
            }
        }
    }

}

.modal-admin-con {
    background-color: rgba($color: #000000, $alpha: 0.5);
    justify-content: center !important;
    padding: 15vw !important;

    .participate-confirm-admin {
        margin-bottom: 1rem;
        display: flex;
        flex-flow: nowrap column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        
        overflow-y: auto !important;

        background-color: white;
        padding: 2rem;
        gap: 1rem;

        position: relative;

        &>i {
            position: absolute;
            top: 0rem;
            right: 0rem;
            width: 1.5rem;
            height: 1.5rem;
            background-color: rgba($color: #000000, $alpha: 0.1);
            color: black;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        &>div {
            width: 100%;
            display: flex;
            flex-flow: wrap row !important;
            align-items: center;
            justify-content: flex-start;

            padding-bottom: .5rem;
            margin-bottom: .5rem;
            border-bottom: 1px solid rgba(0, 0, 0, .1);

            &>span {
                color: black;

                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;

                font-weight: 400;

                small {
                    font-size: .8rem;
                    font-weight: 300;
                }
            }

            &>div {
                display: flex;
                flex-flow: nowrap row;
                align-items: center;
                justify-content: flex-end;


                small {
                    margin-left: .5rem;
                    display: flex;
                    flex-flow: nowrap row;
                }
            }
        }

        & i.bi-check {
            color: green;
            width: 1rem;
            height: 1rem;
            font-size: .9rem;
        }

        & i.bi-emoji-smile {
            color: orange;
            width: 1rem;
            height: 1rem;
            font-size: .9rem;
            margin-right: .5rem;
        }
    }
}

/**

LINKS

*/
.admin-links{
    .inputs{
        display: flex;
        flex-flow: nowrap row;
        align-items: flex-end;
        justify-content: space-around;
        gap: 1rem;

        &>*{
            width: 100%;
            margin-bottom: 0 !important;
        }

        @media screen and (max-width: 700px) {
            flex-flow: nowrap column;
            align-items: stretch;
            justify-content: flex-start;
        }
    }
    .links-p{
        width: 100%;
        display: block;
    }
    ul.links{
        width: 100%;
        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;

        padding: 1rem !important;
        margin: 0 !important;

        background-color: rgba($color: #000000, $alpha: .03);
        border-radius: $radius;
        border: 1px solid $element-water;

        gap: 1rem;

        li{
            width: 100%;
            display: flex;
            flex-flow: nowrap row;
            align-items: center;    
            justify-content: flex-start;
            gap: 1rem;

            border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
            padding-bottom: .5rem;

            &>div{
                overflow-x: auto;
                width: 100%;
                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;
                justify-content: center;
            }

            &>button:first-child{
                margin-left: auto;
            }
        }
    }
}