@import "./themes/globals.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;

  font-family: 'Lato', sans-serif !important;
} 
body{
  overflow-x: hidden !important;
  min-width: min-content !important;

  #root{
    overflow: hidden;
  }
}

textarea{
  min-height: 7rem;
  height: 7rem;

  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

$link: red;


.splash-screen{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  justify-content: center;

  background-color: white;

  opacity: 0;

  transition: opacity 0.5s ease;

  &.active{
    z-index: 10000 !important;
    opacity: 1;
  }

  &.disabled{
    display: none;
  }

  &>img{
    width: 10%;
    margin-bottom: 1rem;

    @media screen and (max-width: 1000px) {
      width: 20%;
    }

    @media screen and (max-width: 600px) {
      width: 30%;
    }
    @media screen and (max-width: 400px) {
      width: 40%;
    }
  }
  &>span{
    font-weight: 500;
    font-style: italic;
    color: rgba($color: #000000, $alpha: 0.8);

    display: block;
    text-align: center;
    margin-top: 1rem;
  }
}