@import "../themes/variables";

.modal {
    z-index: 1000 !important;
}

.modal-card-body {
    border-radius: $radius !important;
    -webkit-border-radius: $radius !important;
    -moz-border-radius: $radius !important;
    -ms-border-radius: $radius !important;
    -o-border-radius: $radius !important;
}

.modal-register {
    .modal-card-body {
        button.delete {
            position: absolute;

            top: 1rem;
            right: 1rem;
        }
    }
    .elements-to-select {
        & .column {
            display: flex;
            flex-flow: nowrap column;
            align-items: center;
            justify-content: center;

            cursor: pointer;

            div {
                display: inherit;
                flex-flow: inherit;
                align-items: inherit;
                justify-content: inherit;
            }

            h2 {
                word-break: normal !important;
            }

            &:hover {
                h2 {
                    &.fire {
                        color: $element-fire;
                    }
                    &.soil {
                        color: $element-soil;
                    }
                    &.wind {
                        color: $element-wind;
                    }
                    &.water {
                        color: $element-water;
                    }
                    &.tv {
                        color: $element-tv;
                    }
                }
                transform: scale(1.1);
                -webkit-transform: scale(1.1);
                -moz-transform: scale(1.1);
                -ms-transform: scale(1.1);
                -o-transform: scale(1.1);
            }

            transition: transform 0.1s ease-in;
            -webkit-transition: transform 0.1s ease-in;
            -moz-transition: transform 0.1s ease-in;
            -ms-transition: transform 0.1s ease-in;
            -o-transition: transform 0.1s ease-in;
        }

        @media screen and (max-width: 769px) {
            & .column {
                border-radius: $radius !important;
                -webkit-border-radius: $radius !important;
                -moz-border-radius: $radius !important;
                -ms-border-radius: $radius !important;
                -o-border-radius: $radius !important;
            }
        }
    }
    img.columns {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .only-selected {
        & div {
            img {
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
            }
        }

        &:hover {
            transform: scale(1) !important;
            -webkit-transform: scale(1) !important;
            -moz-transform: scale(1) !important;
            -ms-transform: scale(1) !important;
            -o-transform: scale(1) !important;

            h2 {
                color: rgb(54, 54, 54) !important;
            }
        }
    }

    .active {
        & div {
            img {
                filter: grayscale(0) !important;
                -webkit-filter: grayscale(0) !important;
            }

            h2 {
                &.fire {
                    color: $element-fire !important;
                }
                &.soil {
                    color: $element-soil !important;
                }
                &.wind {
                    color: $element-wind !important;
                }
                &.water {
                    color: $element-water !important;
                }
                &.tv {
                    color: $element-tv !important;
                }
            }
        }
    }
}

.register-account {
    
}
