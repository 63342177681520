@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');

@import "../themes/variables";

.title-page-component{
    @include section;

    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;

    width: 70%;

    &.center{
        margin: auto;
        & *{
            display: block;
            width: 100%;
            text-align: center;
            
        }
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    h1, span{
        color: white;
    }

    h1{
        font-size: 2rem;
        font-family: 'Aclonica' !important;
    }
    span{
        font-size: 1.1rem;
        font-weight: 300;
    }
}

.title-section-component{
    font-size: 1.25rem;
    color: black;
    font-weight: 800;

    display: block;
    text-align: left;
    width: 100%;

    margin-bottom: 2rem;
}

.title-component{
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;

    &[color="dark"]{
        &>*{
            color: black !important;
        }
    }
    &[color="green"]{
        &>*{
            color: $green-dark !important;
        }
    }
    &[color="blue"]{
        &>*{
            color: $element-water !important;
        }
    }

    &>p{
        font-size: 1.25rem;
        font-weight: 300;

        color: white;
        text-transform: uppercase;
    }
    &>span{
        font-size: 1.75rem;
        font-weight: 800;

        color: white;
        text-transform: uppercase;
    }
}