/*      SWEET ALERT         */
.btn-sweet-alert{
    background-color: var(--alert-color) !important;
    border: none !important;
}
.btn-sweet-alert:hover, .btn-sweet-alert:active, .btn-sweet-alert:focus{
    background-color: var(--alert-color) !important;
    border: none !important;
}
.btn-sweet-alert:focus{
    background-color: var(--alert-color) !important;
    border: none !important;
}

/*      INPUT BUSQUEDA      */
.input-busqueda-comp{
    width: 100%;
    height: 1.5em;

    padding: .25em .5em;
    margin: 0 .5em;

    border-radius: 10em;
    box-shadow: var(--shadow-2);

    outline: none;    
    border: none;

    transition: width .25s ease-in;
}

/*      BUSQEUDA DESPLEGABLE        */
.busqueda-desplegable-comp{
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-end;
}
.busqueda-desplegable-comp>img{
    width: 1.5em;
    height: 1.5em;
    margin: 0 1em;
}
.busqueda-desplegable-comp>img:hover{
    opacity: .5;

    cursor: pointer;
}



/*          ACTIVIDADES INTERCATIVO         */
.actividades-interactivo-comp{
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: space-around;
}
.actividades-interactivo-comp>div{
    width: 30%;
    height: 20em;
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: space-around;
}
.actividades-intercativo-center{
    height: 25em;
}
#seccion-actividades-2 *{
    cursor: pointer;
    transition: fill .15s ease-in, stroke .15s ease-in, ;
}
#R1:hover{
    fill: #0083ee;
    stroke: #0083ee;
}
#R2:hover{
    fill: #62d200;
    stroke: #62d200;
}
#L1:hover{
    fill: #ffa227;
    stroke: #ffa227;
}
#L2:hover{
    fill: #62daff;
    stroke: #62daff;
}

.l1-actividad{
    border-top-color: #f58b00;
}
.l2-actividad{
    border-top-color: #8cd8f0;
}
.r1-actividad{
    border-top-color: #0083ca;
}
.r2-actividad{
    border-top-color: #54b600;
}
.actividades-interactivo-comp span{
    display: block;
    text-align:center;
    font: 400 .9em "Open Sans"; 

    padding: .5em;
    border-top-width: .3em;
    border-top-style: solid;

    border-radius: .20em;
}
#actividades-intercativo-center_hidde{
    display: none;
}

@media screen and (max-width: 850px){
    .actividades-intercativo-center{
        width: 60%;
    }
    .actividades-interactivo-left>div, .actividades-interactivo-right>div{
        width: 20%;
    }
   
    .actividades-interactivo-comp span{
        font-size: .8em;
    }
    
}

@media screen and (max-width: 650px){
    .actividades-interactivo-comp{
        flex-direction: column;
    }
    .actividades-intercativo-center{
        display: none !important;
    }
    #actividades-intercativo-center_hidde{
        display: block;
        height: unset;
        width: 70% !important;
    }
    .actividades-interactivo-comp>div{
        width: 90%;
        height: unset;
        justify-content: space-around;
    }
    .actividades-interactivo-comp>div span{
        margin: 1em;
        font-size: .9em;
    }
}

/*      NUESTROS        PROYECTOS       */

.nuestros-proyectos-comp{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: nowrap column;
            flex-flow: nowrap column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;

    background-color: white;
    padding-top: 3em;
}
.nuestros-proyectos-titulo{
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: inherit;
            flex-flow: inherit;
    -webkit-box-align: inherit;
        -ms-flex-align: inherit;
            align-items: inherit;
    -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
            justify-content: inherit;

    padding: 0 2em;
}
.nuestros-proyectos-titulo>span{
    width: 100%;
    display: block;
    text-align: left;
    font: 400 1.5em "Open Sans";
    line-height: .5em;
}
.nuestros-proyectos-titulo>h1{
    width: 100%;
    display: block;
    text-align: left;
    font: 800 3em "Open Sans";
}
@media screen and (max-width: 500px){
    .nuestros-proyectos-comp{
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .nuestros-proyectos-titulo>span, .nuestros-proyectos-titulo>h1{
        text-align: center;
    }
}
@media screen and (max-width: 400px){
    .nuestros-proyectos-titulo{
        padding: 0;
    }
    .nuestros-proyectos-titulo>span{
        font-size: 1em;
    }
    .nuestros-proyectos-titulo>h1{
        font-size: 2.5em;
    }
}
@media screen and (max-width: 300px){
    .nuestros-proyectos-titulo>h1{
        font-size: 2em;
    }
}

/*          NUESTROS PROYECTOS          */
.nuestros-proyectos-cards{
    width: 100%;
    padding-bottom: 5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: wrap row;
            flex-flow: wrap row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.card-proyecto{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 25%;
            flex: 1 1 25%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 12.5em;
    height: calc(12.5em * 1.25);
    position: relative;

    -webkit-box-align: center;

        -ms-flex-align: center;

            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    margin: 0px;
    margin-top: 1em;
}

@media screen and (max-width: 850px){
    .card-proyecto{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 50%;
                flex: 1 1 50%;
    }
}

.card-proyecto-bgimg{
    width: inherit;
    height: inherit;
    position: absolute;

    z-index: 2;

    source{
        width: inherit;
        height: inherit;
    }
    img{
        width: inherit;
        height: inherit;
    }
}

.card-proyecto-descripcion{
    width: inherit;
    height: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    
}
.card-proyecto-descripcion>span{
    width: 80%;
    padding: .25em;
    height: 80%;

    background-color: var(--black-7);
    color: white;
    font: 700 1em "Open Sans";
    text-transform: uppercase;

    display: block;
    opacity: 0;
    text-align: center;
    cursor: default;

    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -webkit-transition: opacity .25s ease-out, -webkit-transform .5s ease-out;
    transition: opacity .25s ease-out, -webkit-transform .5s ease-out;
    -o-transition: transform .5s ease-out, opacity .25s ease-out;
    transition: transform .5s ease-out, opacity .25s ease-out;
    transition: transform .5s ease-out, opacity .25s ease-out, -webkit-transform .5s ease-out;

    z-index: 3;
}
.card-proyecto:hover .card-proyecto-descripcion>span{
    opacity: 1;
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
}

/*          INPUT COMPONENT     */
.input-component{
    width: calc(100% - 1.5em);
    height: 3em;
    border-radius: var(--radius-input);
    border: none;

    margin: .5em 0;
    overflow-wrap: anywhere;

    font: 500 .9em "Open Sans";

    padding-left: 1.5em;
}
.input-component::-moz-color-swatch ,.input-component::-webkit-color-swatch, input.input-component:focus{
    border-color: transparent;
    border: none !important;
    outline: none;
}
#mensaje-unete-form::placeholder{
    padding-top: .5em;
}
/*          SUBMIT          */
.submit-component{
    width: 10em;
    height: 3em;
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    border: 2px solid var(--r1);
    border-radius: var(--radius-input);
    cursor: pointer;
}
.submit-component>span{
    color: var(--r1);
    font: 700 1.25em "Open Sans";
    text-transform: uppercase;
    cursor: pointer;
}

/*      UNETE COMPONENT     */
.unete-component{
    background-color: rgba(0,0,0,1);
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: flex-start;

    padding-bottom: 1em;
}
span.unete-title-1{
    display: block;
    width: 80%;
    color: white;
    font: 500 1.5em "Open Sans";
    padding-top: 1.5em;
}
h2.unete-title-2{
    display: block;
    width: 80%;
    color: var(--l2);
    font: 700 3em "Open Sans";
    padding-bottom: .5em;
}
.unete-form{
    width: 80%;
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-end;
    justify-content: flex-start;

    input{
        width: 100% !important;
    }
}
@media screen and (max-width: 600px) {
    span.unete-title-1{
        text-align: center;
    }
    h2.unete-title-2{
        text-align: center;
        font-size: 2em;
    }
}
@media screen and (max-width: 400px) {
    span.unete-title-1{
        text-align: center;
        font-size: 1em;
    }
    h2.unete-title-2{
        text-align: center;
        font-size: 1.5em;
    }
}

/*      FACEBOOK IFRAME         */
.facebook-component{
    display: flex;
    justify-content: center;
    padding: 2em 0;
    background-color: white;
}
.facebook-iframe{
    width: 70%;
    height: 30em;
}


/*          EVENTOS         */
.eventos-seccion-component{
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding: 4em 2em;
    z-index: 5;
}
.eventos-seccion-component>img{
    position: absolute;
    top:0;
    left:0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    filter: brightness(.5);

    z-index: 4 !important;
}
.eventos-seccion-component>*{
    z-index: inherit;
}
.eventos-seccion-component>.sigue-texto-eventos-seccion{
    font: 500 1em "Open Sans";
    color: white;
}
.titulo-eventos-seccion{
    font: 800 1.75em "Open Sans";
    color: white;
}

.eventos-lista{
    display: flex;
    flex-flow: wrap row;
    align-items: center;
    justify-content: center;
}

.evento-lista{
    flex: 1 1 calc(50% - 1em);
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-start;
    padding: .25em;
    padding-left: 0;
    margin: .25em;
    margin-left: 0;
}
.evento-lista>img{
    width: 2.5em;
    height: 2.5em;
    padding-right: .25em;
}
.evento-lista-datos{
    font: 500 1em "Open Sans";
    color: white;
}
.evento-lista-datos>div{
    padding: 0 .25em;
}

@media screen and (max-width: 700px) {
    .evento-lista{
        flex: 1 1 calc(90% - 1em);
    }
}


/*          CONTACTANOS CARD        */
.contactanos-card-component{
    background-color: white;
    padding: 2em;
    
}
.contactanos-card-component>div{
    width: 20em;
    padding: 2em;
    margin: 0 auto;
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;

    background-color: var(--r1);
    box-shadow: var(--shadow);
    border-radius: 2em;
}
.contactanos-card-component>div>img{
    width: 5em;
    margin-bottom: 1em;
}
.contactanos-card-component>div>.t1-contactanos-card{
    color: white;
    font: 700 1.25em "Open Sans";  
    display: block;
    width: 100%;
    text-align: center;
}
.contactanos-card-component>div>.t2-contactanos-card{
    color: var(--white-9);
    font: 600 1em "Open Sans";
    display: block;
    width: 100%;
    text-align: center;
}

.btn-llamada-contactanos{
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: flex-start;

    padding: .5em .5em .5em 1em;
    margin-top: 1em;
    background-color: var(--black-8);
    border-radius: 10em;
    color:white;
    font: 600 1em "Open Sans";
    cursor: pointer;
}   
.btn-llamada-contactanos>span{
    transform: translateX(.5em);
    -ms-transform: translateX(.5em);
    -webkit-transform: translateX(.5em);
    -moz-transform: translateX(.5em);
    -o-transform: translateX(.5em);
    transition: transform .15s ease-in;
}
.btn-llamada-contactanos>img{
    width: 1.5em;
    opacity: 0;
    transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    
    transition: opacity .1s ease-in, transform .1s ease-in;
}
.btn-llamada-contactanos:hover img{
    opacity: 1;
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
}
.btn-llamada-contactanos:hover span{
    transform: translateX(0em);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
}

@media screen and (max-width: 30em) {
    .contactanos-card-component>div{
        width: 70%;
    }
}

/*              PATROCINADORES      **/       
.patrocinadores-component{
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;

    padding: 1.5em 1em;
    background-color: white;
}
.patrocinadores-component>div:first-child{
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: center;
}
.patrocinadores-component>div:last-child{
    width: 100%;
    display: flex;
    flex-flow: wrap row;
    align-items: center;
    justify-content: center;
}
.patrocinadores-component>div>span:first-child{
    font: 600 1.25em "Open Sans";
    color: var(--black-9);
}
.patrocinadores-component>div>span:last-child{
    font: 700 2.25em "Open Sans";
    color: var(--black-9);
    margin-bottom: 1em;
}

.patrocinadores-component>div>img{
    margin: 0 2em;
    width: 5em !important;
}

/*          CONTACTANOS         */
.contactanos-component{
    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: flex-start;

    background-color: white;
    padding-top: 3em;
    padding-bottom: 5em;
    border-top: 1px solid var(--black-1);
}
.contactanos-component>span{
    font: 600 1em "Dosis", "Open Sans";
    color: black;
    margin-bottom: .75em;
}
.contactanos-component>a{
    color: var(--r1);
    font: 600 1em "Dosis", "Open Sans";
    margin-bottom: .5em;
}

.contactanos-redes-sociales{
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: center;
    padding-top: .5em;
}
.contactanos-redes-sociales>img{
    width: 2em;
    margin: .2em;
}


/*      VOLUNTARIADO CARD       */
.voluntario-component{
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 3em 0;
}

.voluntariado-card{
    width: 90%;
    background-color: black;
    padding: 1.5em;
}
.voluntariado-card>span{
    color: white;
}
.voluntariado-card>h2{
    color: white;
    font: 700 2.5em "Open Sans";
    text-transform: uppercase;
}
.voluntariado-card>ul{
    color: white;
    padding: 1em;
}
.voluntariado-card>ul li{
    font: 400 .9em "Open Sans";
    list-style-type: disc;
}

#enviar-voluntario-form{
    
}