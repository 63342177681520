@import "../themes/variables";

.links.links-page {
    display: flex;
    flex-flow: nowrap column;

    align-items: center;
    justify-content: center;

    padding: 7% 5%;

    .profile-top {
        width: 70vw;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        margin-bottom: 2rem;

        &>div {
            position: absolute;
            right: 0;
            top: 0;
        }

        &>img {
            width: 9rem;
            height: 9rem;
            object-fit: cover;

            padding: 1rem;
            border-radius: 50%;

            background-color: rgba($color: #000000, $alpha: 0.02);
        }
    }

    .ul-links {
        display: flex;
        flex-flow: nowrap column;
        align-items: center;

        gap: 1rem;

        width: 70vw;

        li {
            cursor: pointer;

            width: 100%;
            text-align: center;
            background-color: rgba($color: #000000, $alpha: 0.05);

            border-radius: .5rem;

            position: relative;

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.07);
            }
            &::before {
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                right: 100%;
                bottom: 0;

                transition: all .5s ease;

                border-radius: 0 .5rem .5rem 0;
                z-index: -5;
            }

            &>a {
                width: 100%;
                height: 100%;
                display: block;
                text-align: center;
                padding: 0.5rem 1rem;

                color: rgba($color: #000000, $alpha: 0.5);
                font-weight: 800;
            }

            &:nth-child(3n+1) {
                & {
                    border-left: .5rem solid $element-soil;
                }

                &::before {

                    background-color: $element-soil;
                }

                &:hover {
                    &>a{
                        color: white;
                    }
                    &::before {
                        right: 0;
                    }
                }
            }

            &:nth-child(3n+2) {
                & {
                    border-left: .5rem solid $element-water;
                }

                &::before {

                    background-color: $element-water;
                }

                &:hover {
                    &>a{
                        color: white;
                    }
                    &::before {
                        right: 0;
                    }
                }
            }

            &:nth-child(3n+3) {
                & {
                    border-left: .5rem solid $element-fire;
                }

                &::before {

                    background-color: $element-fire;
                }

                &:hover {
                    &>a{
                        color: white;
                    }
                    &::before {
                        right: 0;
                    }
                }
            }
        }
    }
}