@import "../themes/variables";

.proyecto-page {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: nowrap column;
    align-items: center;
    justify-content: flex-start;

    footer {
        width: 100%;
        margin: 0 !important;
    }

    &>img {
        width: 100%;
        max-height: 25rem;
        height: 25rem;

        object-fit: cover;
    }

    div.content-info {
        width: 100%;
        @include section;

        &>div {
            width: 100%;

            transform: translateY(-50%);
            background-color: white;

            border-radius: $radius;
            border: 1px solid $black-3;

            box-shadow: $shadow;

            position: relative;

            padding: 1rem 2rem;

            h4 {
                font-size: 1.25rem;
                font-weight: 700;
                color: $black-7;
                margin-bottom: .5rem;
            }

            &>div {

                display: flex;
                flex-flow: nowrap row;
                align-items: flex-end;
                justify-content: space-between;

                width: 100%;
                margin-bottom: .75rem;

                &>div {
                    display: flex;
                    flex-flow: nowrap column;
                    align-items: flex-start;
                    justify-content: center;
                }


            }

            p {
                max-height: 25rem;
                overflow-y: auto;
            }

            .controllers-project {
                position: absolute;

                height: auto;
                width: 100%;

                top: 0rem;
                right: 0;

                transform: translateY(-80%);

                display: flex;
                flex-flow: wrap row;
                align-items: center;
                justify-content: flex-end;

                gap: .5rem;

                button {
                    max-height: 2rem;
                    height: 2rem;

                    /*
                &.is-success{
                    width: 2rem;
                    height: 2rem;
                    padding: 0;

                    i{

                        font-size: 1.5rem;
                    }
                }
                */
                }
            }

            @media screen and (max-width: $mobile) {}
        }
    }


    .title-project {
        width: 60%;

        @media screen and (max-width: $mobile) {
            width: 90%;
        }
    }

    .project-info-content {
        display: flex;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: flex-start;

        margin-top: -2rem;
        width: 100%;

        @include section;

        &>* {
            width: 100%;
        }
    }

    hr {

        @media screen and (max-width: $mobile) {
            width: 90%;
        }
    }

    .images-project {

        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;

        img {
            width: 100%;
            object-fit: cover;
            max-height: 10rem;
            height: 10rem;

            border-radius: $radius;
            box-shadow: $shadow;
        }

        @media screen and (max-width: $tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: $mobile) {
            width: 90%;
        }

        @media screen and (max-width: $mini) {
            width: 90%;

            grid-template-columns: repeat(1, minmax(0, 1fr));

            max-height: 32rem;
            overflow-y: auto;
        }
    }

    .participants-container {
        width: 100% !important;
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: flex-start;

        width: 60%;

        @media screen and (max-width: $mobile) {
            width: 90%;
        }

        small {
            font-size: .9rem;
        }

        img {
            width: 2rem;
            height: 2rem;

            border-radius: 50%;

            object-fit: cover;

            margin-left: -.5rem;

            box-shadow: $shadow-2;

            &:nth-child(2) {
                margin-left: 1rem !important;
            }
        }
    }


}