@import "../themes/variables";

.header-component {
    @include section;

    background-color: rgba(255, 255, 255, 1);
}

.navbar-brand {
    align-items: center !important;

    img {
        display: block;
        width: 4rem;
        height: 4rem;
        min-width: 4rem;
        min-height: 4rem;

        mix-blend-mode: invert !important;
    }
}

.navbar-item {
    font-weight: 600;

    &.account {
        opacity: .7;

        padding: 0;
        margin-right: auto;

        &>img {
            width: 2.25rem;
            height: 2.25rem;

            min-width: 2.25rem;
            min-height: 2.25rem;

            display: block;
        }
    }
}

.navbar-end,
.navbar-brand,
.navbar,
.navbar-start {
    align-items: center !important;
}

.navbar-start {
    width: 100% !important;
    justify-content: flex-end !important;
    margin-right: 2rem !important;
}

.navbar-end {
    display: flex !important;
    flex-flow: nowrap row !important;

    gap: 1rem;

    @media screen and (max-width: 1024px) {
        align-items: center !important;
        flex-flow: nowrap column-reverse !important;

        gap: 0;

        .btn-donar {
            color: black;
            background-color: transparent;

            width: 100%;
            text-align: center;
            margin: 0 !important;
            padding: 1rem 0 0 0 !important;

            box-shadow: none !important;

            border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
            font-weight: 300;

        }

        a.account {
            padding-left: 10px;
        }
    }
}

.navbar-menu {
    box-shadow: none !important;
    background-color: transparent !important;
    overflow-x: hidden !important;
}

.methods-payment {
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    gap: .5rem;

    &>.tabs {
        margin-bottom: 0;

        ul {
            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-evenly;

            border-bottom: none;

            gap: 1rem;

            & .hr {
                width: 1px;
                height: 100%;

                background-color: rgba(0, 0, 0, 0.2);
            }
        }

        button {
            background-color: transparent;
            border: none !important;
            box-shadow: none !important;
            text-align: center;

            font-family: "Aclonica" !important;
            font-size: .9rem;
            font-weight: 200;
            text-transform: uppercase;

            border-radius: 0;

            &:focus,
            &:focus-visible,
            &:focus-within,
            &:active {
                border: none;
                border-color: transparent;
                outline: none;
            }
        }
    }

    &>.content>div {
        flex-flow: nowrap column;
        align-items: flex-start;

        p {
            font-weight: 300;
        }

        img {
            max-width: 100%;
            width: 100%;
            height: 100%;
            max-height: 100%;
        }
    }
}

.navbar-dropdown {
    left: unset !important;
    right: 0 !important;
    width: auto !important;
}

.navbar-link {}

:root {
    /*  BORDER RADIUS   */
    --radius: 5px;
    --radius-2: 10px;
}

/*      In HEADEr LANGUAGE TRANLATOR        */
.change-language-menu {
    position: relative;

    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.change-language-menu>span {
    text-transform: uppercase;
    color: black;
    font-weight: 100;
    font-size: .75em;
}

.change-language-menu>svg {
    color: black;
    fill: black;
    height: 1.25em;
    width: 1.25em;
}

.change-language-menu>svg:hover~ul {
    display: flex;
}

.change-language-menu>span:hover~ul {
    display: flex;
}

.change-language-menu>ul {
    display: none;

    position: absolute;
    right: 0;
    top: 1rem;

    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: center;

    padding-top: 1rem;


}

.change-language-menu>ul:hover {
    display: flex;
}

.change-language-menu>ul>li {
    padding: .5rem 1rem;
    cursor: pointer;
    background-color: black;
    text-align: center;
    color: white;
}

.change-language-menu>ul>li:first-child {
    padding-top: 1rem;
}

.change-language-menu>ul>li:last-child {
    padding-bottom: .5rem;
}

.change-language-menu>ul>li:last-child {
    border-radius: 0 0 var(--radius) var(--radius);
    -webkit-border-radius: 0 0 var(--radius) var(--radius);
    -moz-border-radius: 0 0 var(--radius) var(--radius);
    -ms-border-radius: 0 0 var(--radius) var(--radius);
    -o-border-radius: 0 0 var(--radius) var(--radius);
}

@media screen and (max-width: 550px) {
    .change-language-menu>ul {
        right: unset;
    }

    .change-language-menu>ul>li {
        background-color: transparent;
    }
}