@import "../themes/variables";

.nosotros-page {
    &>.history {
        @include section;

        padding-top: 4rem;
        padding-bottom: 4rem;

        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;

        gap: 2rem;

        &>p {
            color: black;
        }

        &>.skills {
            margin-top: 2rem;

            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 2rem;

            &>div {
                padding: 1rem;

                display: flex;
                flex-flow: nowrap column;
                align-items: center;
                justify-content: center;

                &>* {
                    color: white;
                    text-align: center;
                }

                &>span {
                    font-weight: 800;
                    font-size: 1.5rem;
                }

                &>p {
                    font-weight: 300;
                    font-size: 1rem;
                }
            }

            @media screen and (max-width: 800px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media screen and (max-width: 400px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }

    &>.about-us {
        @include section;

        padding-top: 4rem;
        padding-bottom: 4rem;

        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        gap: 2rem;

        &>.about {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 4px) minmax(0, 1fr);
            gap: 3rem;

            @media screen and (max-width: 800px) {
                grid-template-columns: minmax(0, 1fr);

                &>div.hr {
                    display: none;
                }
            }

            &>div {
                display: flex;
                flex-flow: nowrap column;
                align-items: center;

                &.hr {
                    width: 4px;
                    background-color: $green-dark;
                    border-radius: 2px;
                }

                &>h3 {
                    font-size: 1.25rem;
                    text-decoration: underline;
                    text-transform: uppercase;

                    margin-bottom: 1rem;
                }

                &>p {
                    text-align: justify;
                }
            }
        }
    }

    &>.propacha-is {
        @include section;
        padding-bottom: 4rem;

        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;

        gap: 2rem;

        &>p {
            text-align: justify;
        }
    }

    &>.out-team {
        @include section;
        padding-top: 2rem;

        img {
            width: 100%;
            min-width: 100%;
            border-radius: $radius;

            object-fit: cover;

            max-height: 20rem;
            object-position: 50% 10%;
        }
    }

    .us-swiper {
        max-width: 100%;

        padding-top: 2rem;
        padding-bottom: 5rem;

        .team-int {
            img {
                width: 8rem;
                height: 8rem;
                max-width: 8rem;
                max-height: 8rem;
                min-width: 8rem;
                min-height: 8rem;

                object-fit: cover;

                border-radius: 50%;
            }

            .content {
                display: flex;
                flex-flow: nowrap column;
                align-items: center;
                justify-content: center;

                &>div {
                    margin-top: 2rem;
                    display: flex;
                    flex-flow: nowrap column;
                    align-items: center;
                    justify-content: center;

                    * {
                        text-align: center;
                    }

                    p {
                        text-align: justify;
                    }
                }
            }
        }
    }

    .swiper-team {

        //@include section;
        .swiper-wrapper {
            padding-bottom: 4rem;
        }

        .swiper-pagination {
            bottom: 1rem;
        }

        .swiper-button-next,
        .swiper-button-prev {
            transform: scale(.5);
            color: black;
        }

        .title-swiper-descriptive {
            display: block;
            text-align: center;
            width: 100%;
            font-weight: 600;
            font-size: 1.25rem;
            margin-top: 1rem;
            padding-bottom: .5rem;

            border-bottom: 1px solid rgba($color: $green-dark, $alpha: 0.25);
        }
        .container-team{

            //border-bottom: 2px solid rgba($color: $green-dark, $alpha: 0.25);
        }
    }
}