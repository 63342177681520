@import "../themes/variables";


.home-v2{
    .swiper-slide{
        min-height: unset !important;
        aspect-ratio: 2.48 / 1 !important;
        max-height: unset !important;
        height: 80vh !important;
        img{
            object-position: 0 0;
            object-fit: contain !important;
            padding: 2rem 0;
        }
    }
}

.home-page {
    .content-page {
        background-color: white;
    }

    .challenges-home {
        @include section-m;

        display: block;
        width: fit-content;
        padding: 1.5rem;

        background-color: white;

        transform: translateY(-50%);

        border-radius: calc($radius / 2);

        box-shadow: 0 0 15px 5px rgba($color: #000000, $alpha: 0.1);

        & > span[color="green"] {
            display: block;
            margin-bottom: 1rem;
        }

        & .content-slide-c {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 2rem;
            row-gap: 0.75rem;
            height: 100%;

            & > span {
                display: inline-grid;
                display: -ms-inline-grid;
                display: -moz-inline-grid;

                grid-template-columns: 1fr minmax(0, 4fr);
                gap: 0.5rem;
                vertical-align: middle;

                * {
                    display: flex;
                    align-items: center;
                }

                p {
                    line-height: 1.1rem;
                }
            }
        }

        span[color="green"] {
            font-weight: 600;
        }

        @media screen and (max-width: 600px) {
            & .content-slide-c {
                grid-template-columns: minmax(0, 1fr);
            }

            & .swiper.swiper-initialized {
                max-width: 60vw !important;
            }
        }
    }

    .our-projects-home {
        @include section;

        max-width: 100vw;

        padding-top: 3rem;
        padding-bottom: 3rem;

        //        background-image: url("/assets/images/bg/our-projects.jpg");

        background-position: 0 0;
        background-size: cover;
        backdrop-filter: brightness(60%);

        & > .title-section {
            display: flex;
            flex-flow: wrap row;
            align-items: center;
            justify-content: space-between;

            gap: 1rem;
        }

        .our-projects-swiper {
            margin-top: 2rem;

            .swiper-slide {
                display: flex;
                flex-flow: nowrap row;
                align-items: stretch;
                justify-content: flex-start;

                @media screen and (max-width: 450px) {
                    flex-flow: nowrap column;

                    img {
                        border-radius: $radius $radius 0 0 !important;
                        max-width: 100% !important;
                        width: 100% !important;
                    }

                    & > div {
                        border-radius: 0 0 $radius $radius !important;
                    }
                }

                img {
                    width: 10rem;
                    height: 10rem;
                    max-width: 10rem;
                    max-height: 10rem;
                    min-width: 10rem;
                    min-height: 10rem;

                    object-fit: cover;

                    border-radius: $radius 0 0 $radius;
                }

                & > div {
                    background-color: rgba($color: #000000, $alpha: 0.4);
                    padding: 1rem 2rem;

                    max-height: calc(10rem);
                    height: 10rem;
                    width: 100%;

                    overflow-y: auto;
                    overflow-x: hidden;

                    border-radius: 0 $radius $radius 0;

                    box-sizing: border-box;

                    & * {
                        color: white;
                        font-style: italic;
                    }
                }
            }

            .swiper-pagination {
                bottom: 0;
            }

            .swiper-wrapper {
                padding-bottom: 2rem;
            }

            .swiper-pagination-bullet-active {
                background-color: white;
            }
        }
    }
}

.form-home {
    @include section;

    padding-top: 3rem;
    padding-bottom: 3rem;
}

.how-help-home {
    @include section;

    padding-bottom: 3rem;

    .tabs-component {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
        gap: 2rem;

        @media screen and (max-width: 800px) {
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
            gap: 1rem;
        }

        @media screen and (max-width: 500px) {
            grid-template-columns: minmax(0, 1fr);

            & > .content {
                ul {
                    margin: 0;
                }
            }
        }

        & > .tabs {
            border-bottom: none;
            overflow-x: hidden;

            ul {
                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;

                border-bottom: none;

                margin: 0;

                & > li {
                    width: 100%;

                    background-color: $bg;

                    &.is-active {
                        span {
                            color: $green-dark;
                            border-bottom-color: $green-dark;
                            font-weight: 800;
                        }
                    }

                    span {
                        display: flex;
                        flex-flow: nowrap row;
                        align-items: center;
                        justify-content: space-between;
                        padding: 1rem 2rem 1rem 2rem;

                        gap: 0.5rem;

                        img {
                            display: block;
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                }
            }
        }

        & > .content {
            li {
                transition: opacity 0.25s ease;

                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;
                justify-content: flex-start;

                .button-and-element {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                small {
                    margin-bottom: 0.5rem;
                }

                span {
                    font-size: 1.1rem;

                    color: black;
                    font-weight: 700;
                    margin-bottom: 1rem;
                }

                p {
                    text-align: justify;
                }
            }

            li.disabled {
                opacity: 0;
                width: 0;
                height: 0;
                margin: 0;
                padding: 0;
                display: none;
            }

            li.is-active {
                opacity: 1;
                width: 100%;
            }

            .photo-group {
                background-color: #000000;
            }
        }
    }
}

.home-page {
    .content-page {
        position: relative;

        & > .before {
            max-height: 3rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            position: absolute;

            top: 1rem;
            right: 10%;

            & > span {
                font-family: "Aclonica" !important;
                color: rgba($color: #000000, $alpha: 0.8);
                font-size: 1.25rem;

                @media screen and (max-width: 1000px) {
                    display: none;
                }
            }

            & > img {
                width: 4rem;
                height: 4rem;

                margin-left: 1rem;

                @media screen and (max-width: 400px) {
                    display: none;
                }
            }
        }
    }
}

.Aliados-carrusel {
    margin-bottom: 1rem;
    h2 {
        margin-bottom: 1rem !important;
    }
    .tit {
        width: 100%;
        padding-left: 10%;
    }
}

.header-group {
    display: flex;
    flex-direction: row;
    height: 15rem;
    .photo-group {
        max-height: 100%;
        height: inherit;
        object-fit: cover;
        border-radius: 5px;
    }
    .text-group {
        p {
            width: 80%;
            height: 100%;
            padding-left: 2rem;
        }
    }
}

.lista-eventos {
    margin-bottom: 1rem;
    #act {
        background-color: #00000005;
        padding: 0.2rem;
        border-radius: 5px;
        .img-desc {
            display: flex;
            flex-direction: row;
            gap: 0.7rem;
            align-items: center;
            .container-imgs{
                display: grid;
                gap: 1rem;
                max-width: 12rem;
                
                &>img:first-child{
                    grid-column-start: 1;
                    grid-column-end: 3;
                    border-radius: 5px;
                    width: 15rem;
                    height: 9rem;
                    object-fit: cover;
                }
            }
            .desc-act {
                height: auto;
                width: 70%;
                max-height: 11.5rem;
                overflow-y: auto;
                p{
                    font-size: 12px;
                }
                
            }

        }
    }
}


// modal


.modal-content{
    border-radius: 5px;
    &>div:first-child{
        margin:auto;
        padding: 1rem;
        border-radius: 5px;
        background-color: white;
        
    }
}

.linea-horizontal {
    width: 100%; /* Establece la longitud de la línea al 100% del contenedor */
    height: 1px; /* Grosor de la línea */
    background-color: #000; /* Color de la línea */
}



.img-modal-act{
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    >img{
        margin: 0.2rem;
        border-radius:5px;
        height: 50%;
        width: 70%;
        object-fit: cover;
    }
}

.imgHome{
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    object-fit: cover;


    &.positioned {
        position: absolute;
        z-index: -5;
    }
}








.fondoo{
    img{
        object-fit: cover !important;
        max-width: inherit !important;
    }
    img, video{
        max-width: inherit !important;
    }
}