@import "../themes/variables";

.all-proyects-page{
    padding-top: 4rem;
    &>div{
        @include section;
    }
}

.projects{
    margin-top: 2rem;
    margin-bottom: 4rem;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    gap: 2rem;
    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1,minmax(0,1fr));
    }
}
.project {
    justify-content: space-around;
    max-height: 12rem;
    min-height: 10rem;
    overflow: hidden;
    .img-name-description-project{
        position: relative;
        display: inline-block;
        height: 100%;
        width: 100%;
        object-fit: cover;
        .img-project{
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: .5rem;
        }
        .name-description {
            color: white;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0;

            padding: 1rem 1.25em;
        
            background-color: rgba(0,0,0,.3);
            box-shadow: 0 -20px 10px 5px rgba(0,0,0,.3);
            border-radius: 0 0 $radius $radius;
            text-shadow:  rgba(0, 0, 0, 0.518) 0 0 7px;

            span{
                font-size: 1.1rem;
            }
            small{
                font-size: .9rem;
            }
        }
    }
}

