@import "../../themes/variables";

.control {
    span.icon {
        cursor: pointer;
        pointer-events: initial !important;
        &:hover {
            i {
                opacity: 0.8;
            }
        }
    }
}