@import "../themes/variables";

$bg: #1E1F1A;
$text: #C2C2C2;

footer#footer {
    display: flex;
    flex-flow: nowrap column;
    align-items: center;

    @include section;

    padding-top: 2rem;
    padding-bottom: 2rem;

    background-color: $bg;

    & * {
        color: $text !important;
    }

    & svg {
        * {
            fill: $text;
        }
    }

    &>h2 {
        font-family: 'Aclonica' !important;
        font-size: 2rem;

        @media screen and (max-width: 300px) {
            font-size: 1.5rem;
        }
    }

    &>h5 {
        margin-bottom: 2rem;
        display: block;

    }

    &>.links {
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: center;

        gap: 2rem;

        margin-bottom: 2rem;

        & a {
            text-decoration: underline;
            text-align: center;
            font-weight: 300;

            &:hover {
                text-decoration: none;
            }
        }

        @media screen and (max-width: 800px) {
            flex-flow: nowrap column;
            align-items: center;
            justify-content: center;

            gap: 1rem;
        }
    }

    &>.network-links {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 1rem;

        margin-bottom: 1rem;

        &>* {
            svg {
                width: 2rem;
                height: 2rem;
                min-width: 2rem;
                min-height: 2rem;
                max-width: 2rem;
                max-height: 2rem;
            }
        }

        @media screen and (max-width: 300px) {
            gap: .5rem;
            &>* {
                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    min-width: 1.5rem;
                    min-height: 1.5rem;
                    max-width: 1.5rem;
                    max-height: 1.5rem;
                }
            }
        }
    }

    &>p{
        font-size: .9rem;
        font-weight: 300;
        text-align: center;
    }
}