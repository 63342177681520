@import "variables";

.cards-manager {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;

    @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
    }

    @media screen and (max-width: $mini) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: .5rem;
    }
}

button.is-green,
.button.is-green {
    background-color: $green-dark;
    color: white;
    border: none;
    padding: 0 1.5rem;

    &:hover {
        color: white;
        //filter: contrast(.8);
        background-color: $green-dark;
        opacity: .8;
    }
}

button:disabled {
    opacity: .8;
    cursor: default;
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: black !important;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
        color: black !important;
    }

    &>* {
        color: black !important;

        &:hover {
            color: black !important;
        }
    }

    &.with-med {
        position: relative;
        cursor: default;
        /*
        border-bottom-left-radius: 1rem !important;
        border-bottom-right-radius: 1rem !important;
*/
    }

    &>.med-line {
        position: absolute;

        bottom: 0;
        right: calc(100% - var(--aria-percent));
        left: 0;

        height: 4px;
        background-color: var(--color);

        display: block;
        border-radius: 10rem;
    }
}

section {
    padding: 3rem 10%;
}

.head-img {
    display: block;
    width: 100%;
    max-width: 100%;

    height: 80vh;
    object-fit: cover;

    filter: brightness(.5);

    &.positioned {
        position: absolute;

        z-index: -5;

        //animation: zoomAndSlide 30s ease-in-out infinite; 
    }
}

@keyframes zoomAndSlide {
    0% {
        transform: scale(1) translateX(0%); // Escala inicial y sin desplazamiento
    }

    50% {
        transform: scale(1.1) translateX(1%); // Escala máxima y desplazamiento hacia la derecha (ajusta el valor para el desplazamiento)
    }

    100% {
        transform: scale(1) translateX(0%); // Escala de regreso y sin desplazamiento
    }
}

*[color="green"] {
    color: $green-dark;
}

button[color="green"] {
    background-color: $green-dark;
    color: white;

    border: none;

    &:hover {
        color: white;
        opacity: .8;
    }
}

*[color="element-blue"] {
    background-color: $element-water;
}

*[color="element-green"] {
    background-color: $element-soil;
}

*[color="element-orange"] {
    background-color: $element-fire;
}

*[color="element-lightblue"] {
    background-color: $element-wind;
}

/**/
.triangle {
    width: 100%;
    height: 6rem;

    margin-top: -6rem;

    display: block;
}

/**/
.page {
    .title-page {
        height: 80vh;

        display: flex;
        align-items: center;

        transform: translateY(0%);

        @media screen and (max-width: 600px) {
            transform: translateY(-10%);
        }
    }
}

/**/

.swiper-pagination-bullet-active {
    background-color: $green-dark !important;
}

.swiper-wrapper {
    align-items: stretch;

    max-height: 8rem;

    &>.swiper-slide {
        height: unset !important;

        img {
            height: 100%;
            max-height: 8rem;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;

            border-radius: 5px;

            transition: all .25s ease;

            cursor: pointer;

            &:hover {
                height: 100%;
                max-height: 100% !important;
            }
        }
    }
}

.swiper.swiper-initialized {
    padding-bottom: 3rem;
}

.swiper-pagination {
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

/**/

.fire-txt *,
.fire-txt a {
    cursor: pointer;
    color: $element-fire !important;
    border-bottom-color: $element-fire !important;
}

.water-txt *,
.water-txt a {
    cursor: pointer;
    color: $element-water !important;
    border-bottom-color: $element-water !important;
}

.soil-txt *,
.soil-txt a {
    cursor: pointer;
    color: $element-soil !important;
    border-bottom-color: $element-soil !important;
}

.wind-txt *,
.wind-txt a {
    cursor: pointer;
    color: $element-wind !important;
    border-bottom-color: $element-wind !important;
}

.tv-txt *,
.tv-txt a {
    cursor: pointer;
    color: $element-tv !important;
    border-bottom-color: $element-tv !important;
}

/**/

.divider {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    margin: 1rem 0;

    &>div{
        width: 100%;
        height: 1px;
        border-top: 1px solid rgba(0, 0, 0, 0.5);
    }
}