@import "../themes/variables";

.proyecto-propacha-page {
    &>* {
        &:not(img) {
            width: 100%;
        }
    }

    .how-help-home {
        .tabs {
            li >a {
                display: flex;
                flex-flow: nowrap row;
                align-items: center;
                justify-content: space-between;

                img{
                    max-width: 2rem;
                    max-height: 2rem;
                    width: 2rem;
                    height: 2rem;

                    object-fit: cover   
                }
            }
        }
    }

    .gallery {
        @include section;

        img {
            max-width: 100%;
        }

        .activity {

            max-width: 100%;
            display: flex;
            flex-flow: nowrap column;
            align-items: center;
            justify-content: center;

            &>.swiper {
                max-width: 100%;
            }

            img {
                margin-bottom: 2rem;
            }
        }

        .swiper-slide {
            max-width: 100%;
        }

        .is-active {
            display: flex;

        }

        .disabled {
            display: none;
        }
    }
}

.img-modal-act{
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    >img{
        margin: 0.2rem;
        border-radius:5px;
        height: 50%;
        width: 70%;
        object-fit: cover;
    }
}
.modal-content{
    border-radius: 5px;
    height: 30rem;
    &>div:first-child{
        margin:auto;
        padding: 1rem;
        border-radius: 5px;
        background-color: white;
        
    }
}

.linea-horizontal {
    width: 100%; /* Establece la longitud de la línea al 100% del contenedor */
    height: 1px; /* Grosor de la línea */
    background-color: #000; /* Color de la línea */
}

