@import "../themes/variables";

.cbjlc-page {
    .history-cbjlc {
        @include section;

        padding-top: 4rem;
        padding-bottom: 4rem;

        padding-bottom: 2rem;

        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;

        gap: 2rem;

        h2 {
            margin-bottom: 0;
        }

        .gallery {
            display: flex;
            flex-flow: nowrap column;
            align-items: center;
            gap: 1rem;
        }

        .swiper-wrapper {
            padding-bottom: 3rem;
        }

        .swiper-pagination {
            bottom: 1rem;
        }

        p {
            text-align: justify;
        }
    }

    .swiper {
        margin-top: 3rem;
    }

    .swiper-wrapper {
        max-height: 10rem !important;
    }

    .memo-inst {
        display: block;
        width: 25%;
        margin: 0 auto;

        img {
            border-radius: $radius;
        }

        &::after {
            content: "Accede a la memoria institucional";
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}


.history-cbjlc .modal-content {
    background-color: white;
    border-radius: $radius;

    .content>div {
        padding: 1rem;
    }

    .content>.f1 {
        align-items: center;
        justify-content: center;
    }
}

.donate-tt {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    gap: 2rem;

    .nameEquipos{
        display: flex;
        flex-direction: row;
    }
    .swiper-wrapper {
        max-height: 100vh !important;
    }

    .t2 {
        display: flex;
        padding: 2rem;
        height: inherit;

        flex-flow: nowrap column;
        align-items: center;
        justify-content: center;

        .mb-4 {
            margin-bottom: 0.5rem !important;
        }
        .fraceDonar{
            margin-bottom: 0.5rem;
            font-style: italic;
        }
        &>* {
            text-align: center;
            width: 100%;
            display: block;
            line-height: 1.2rem;

            b {
                font-family: monospace;
                font-size: .8rem;
                font-weight: 800;
            }
        }

        border-radius: 5px;
        box-shadow: 0 0 15px 5px rgba($color: #000000, $alpha: 0.08);
    }


    .t3 {
        display: flex;
        flex-flow: nowrap column;
        height: inherit;
        align-items: center;
        justify-content: space-between;

        border-radius: 5px;
        box-shadow: 0 0 15px 5px rgba($color: #000000, $alpha: 0.08);

        padding-top: 2rem;

        border-radius: 5px;

        .swiper-wrapper {
            // max-height: 50% !important;

            img {
                border-radius: 0;
            }
        }

        .slide-t3 {
            display: grid;
            grid-template-rows: repeat(8, minmax(0, 1fr));
            gap: 1rem;
            span {
                font-size: .9rem;
                font-weight: 700;
            }
            p{
                font-size: .7rem;
                overflow-y: auto;
                height: 100%;
            }

            * {
                line-height: 1.1rem;
            }
        }
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        max-height: unset !important;
        height: unset !important;
    }
    @media screen and (max-width: 1400px) {
        .nameEquipos{
            left:20% !important;
            padding-bottom: 0.5rem !important;
            padding-top: 0.3rem !important;
        }
    }
    
}

.swiper-slide.logo-suggars:hover{
    transform: scale(2);
    transition: all ease-in .2s;
}




.cont-sup{
    display: flex;
    align-items: center;
    height: 100%;
}
.cont{
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    &.title-page-component{
        align-items: center;
        justify-content: center;
    }

}
.img-proy{
    object-fit: contain;
    height: unset !important;
    margin-bottom: 1rem;
}

.logos-huerto{
   img{
    height: 6rem;
   } 
}

