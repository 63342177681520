$marron: #dfb758;
$marron-rgb: 223, 183, 88;

$green-fs: #6cf973;
$green-fs-rgb: 108, 249, 115;

$shadow-color: rgba(0, 0, 0, 0.03);
$shadow-color-2: rgba(0, 0, 0, 0.05);

/*  ACTIVIDADES PROPACHA COLORES        */
$l1: #f58b00;
$l1-rgb: 245, 139, 0;
$l2: #8cd8f0;
$l2-rgb: 140, 216, 240;
$r1: #0083ca;
$r1-rgb: 0, 131, 202;
$r2: #54b600;
$r2-rgb: 84, 182, 0;

$alert-color: #0083ca;

$black-1: rgba(0, 0, 0, 0.1);
$black-2: rgba(0, 0, 0, 0.2);
$black-3: rgba(0, 0, 0, 0.3);
$black-4: rgba(0, 0, 0, 0.4);
$black-5: rgba(0, 0, 0, 0.5);
$black-6: rgba(0, 0, 0, 0.6);
$black-7: rgba(0, 0, 0, 0.7);
$black-8: rgba(0, 0, 0, 0.8);
$black-9: rgba(0, 0, 0, 0.9);
$black: rgba(0, 0, 0, 1);

$bg: rgba(0, 0, 0, 0.03);

$white-1: rgba(255, 255, 255, 0.1);
$white-2: rgba(255, 255, 255, 0.2);
$white-3: rgba(255, 255, 255, 0.3);
$white-4: rgba(255, 255, 255, 0.4);
$white-5: rgba(255, 255, 255, 0.5);
$white-6: rgba(255, 255, 255, 0.6);
$white-7: rgba(255, 255, 255, 0.7);
$white-8: rgba(255, 255, 255, 0.8);
$white-9: rgba(255, 255, 255, 0.9);
$white: rgba(255, 255, 255, 1);

$element-fire: #ff7500;
$element-soil: #92ba01;
$element-wind: #8dd9f1;
$element-water: #0084cc;
$element-tv: #faa512;

$green-dark: #00651E;

/*  otros   */

$shadow: 0 0 5px 5px $shadow-color;
$shadow-2: 0 0 5px 5px $shadow-color-2;

$radius-input: 5px;
$radius: 10px;

//BREAKPOINTS
$tablet: 992px;
$mobile: 768px;
$mini: 400px;

//CLASSES

.contrast {
    background-color: $bg;
}


/**/

@mixin section{
    padding-right: 10%;
    padding-left: 10%;
}
@mixin section-m{
    margin-right: 10%;
    margin-left: 10%;
}