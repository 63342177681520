@font-face {
    font-family: 'Aclonica';
    src: local('Aclonica Regular'), local('Aclonica-Regular'),
        url('themes/fonts/Aclonica-Regular.woff2') format('woff2'),
        url('themes/fonts/Aclonica-Regular.woff') format('woff'),
        url('themes/fonts/Aclonica-Regular.ttf') format('truetype'),
        url('themes/fonts/Aclonica-Regular.svg#Aclonica-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

:root{
    /*  colores    */

    --marron: #dfb758;
    --marron-rgb: 223, 183, 88;

    --green-fs: #6CF973;
    --green-fs-rgb: 108, 249, 115;


    --shadow-color: rgba( 0,0,0,0.03);
    --shadow-color-2: rgba( 0,0,0,0.05);

    /*  ACTIVIDADES PROPACHA COLORES        */
    --l1: #f58b00;  
    --l1-rgb: 245, 139, 0;
    --l2: #8cd8f0;
    --l2-rgb: 140, 216, 240;
    --r1: #0083ca;
    --r1-rgb: 0, 131, 202;
    --r2: #54b600;
    --r2-rgb: 84, 182, 0;


    --alert-color: #0083ca;


    --black-1: rgba(0,0,0,0.1);
    --black-2: rgba(0,0,0,0.2);
    --black-3: rgba(0,0,0,0.3);
    --black-4: rgba(0,0,0,0.4);
    --black-5: rgba(0,0,0,0.5);
    --black-6: rgba(0,0,0,0.6);
    --black-7: rgba(0,0,0,0.7);
    --black-8: rgba(0,0,0,0.8);
    --black-9: rgba(0,0,0,0.9);
    --black: rgba(0,0,0,1);

    --white-1: rgba(255,255,255,.1);
    --white-2: rgba(255,255,255,.2);
    --white-3: rgba(255,255,255,.3);
    --white-4: rgba(255,255,255,.4);
    --white-5: rgba(255,255,255,.5);
    --white-6: rgba(255,255,255,.6);
    --white-7: rgba(255,255,255,.7);
    --white-8: rgba(255,255,255,.8);
    --white-9: rgba(255,255,255,.9);
    --white: rgba(255,255,255,1);

    /*  otros   */

    --shadow: 0 0 5px 5px var(--shadow-color);
    --shadow-2: 0 0 5px 5px var(--shadow-color-2);

    --radius-input: 5px;
    --radius: 10px;

}
*{
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    list-style: none;
    box-sizing: border-box;
}