@import "../themes/variables";

$element: var(--color);

.profile-page {
    display: flex;

    position: relative;

    flex-flow: nowrap column;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.05);

    .title-component {
        * {
            color: $element !important;
        }
    }

    .bg {
        width: 100%;
        min-height: 25rem;
        max-height: 25rem;
        height: 22rem;

        background-color: white;

        z-index: 1;

        &.fire {
            background-image: url("https://propacha.org/assets/images/elements/fire-bg.jpg");

            background-position: 50% 70%;
        }

        &.water {
            background-image: url("https://propacha.org/assets/images/elements/water-bg.jpg");

            background-position: 50% 10%;
        }

        &.soil {
            background-image: url("https://propacha.org/assets/images/elements/soil-bg.jpg");

            background-position: 50% 50%;
        }

        &.wind {
            background-image: url("https://propacha.org/assets/images/elements/wind-bg.jpg");
        }

        &.tv {
            background-image: url("https://propacha.org/assets/images/elements/propachatv.png");

            background-size: 4rem;
            background-clip: 2rem;

            background-repeat: space;
            resize: both;
        }

        background-size: cover;

        overflow: auto;
    }

    .profile-content {
        @include section;
        z-index: 5;

        width: 100%;

        &>* {
            z-index: inherit;
        }

        transform: translateY(-50%);

        margin-bottom: -4rem;

        @media screen and (max-width: $mobile) {
            transform: translateY(-0%);
            margin-top: -50%;
            margin-bottom: 2rem;
        }

        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;

        .info-container {
            max-height: 14rem;

            display: flex;
            flex-flow: nowrap row;
            align-items: stretch;
            justify-content: flex-start;

            background-color: white;

            position: relative;

            .update-image {
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                background-color: white;
                width: 1.5rem;
                height: 1.5rem;
                max-width: 1.5rem;
                max-height: 1.5rem;

                display: flex;
                align-items: center;
                justify-content: center;

                color: $element;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;

                padding: 0;

                input {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;

                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    max-width: 1.5rem !important;
                    max-height: 1.5rem !important;
                    min-width: 1.5rem !important;
                    min-height: 1.5rem !important;

                    z-index: 100 !important;

                    opacity: 0;

                }

                i {}

                &:hover {
                    background-color: $element;
                    color: white;
                }
            }

            img {
                width: 14rem;
                min-width: 14rem;
                height: 14rem;

                border-radius: $radius;
                -webkit-border-radius: $radius;
                -moz-border-radius: $radius;
                -ms-border-radius: $radius;
                -o-border-radius: $radius;

                object-fit: cover;
            }

            &>div {
                padding: 1rem 2rem;

                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;
                justify-content: space-between;

                .info-all {
                    display: flex;
                    flex-flow: nowrap row;
                    align-items: flex-start;
                    justify-content: space-between;

                    @media screen and (max-width: 700px) {
                        flex-flow: nowrap column-reverse;
                        align-items: stretch;

                        .info-user-side-right-points {
                            margin-left: 0 !important;
                        }
                    }

                    &>div {
                        &:first-child {
                            * {
                                display: block;
                            }

                            span {
                                font-weight: 500;
                                font-size: 1.1rem;
                                margin-bottom: 0.5rem;
                            }

                            p {
                                font-weight: 300;
                                font-size: 1rem;
                            }
                        }

                        &:last-child {
                            margin-left: 2rem;

                            div {
                                display: flex;
                                flex-flow: nowrap row;
                                align-items: center;
                                margin-bottom: 0.25rem;

                                p {
                                    margin-right: 0.25rem;
                                }

                                i {
                                    color: $element;
                                }
                            }

                            small {}
                        }
                    }

                    &+div {
                        width: 100%;

                        progress {}
                    }
                }
            }

            border-radius: $radius;
            -webkit-border-radius: $radius;
            -moz-border-radius: $radius;
            -ms-border-radius: $radius;
            -o-border-radius: $radius;
        }


    }

    div.img-profile {
        padding: 0 !important;

        position: relative;
    }

    .points-progress {
        display: flex;
        flex-flow: nowrap row;
        align-items: center;

        margin-top: 1rem;

        small {
            display: flex;
            flex-flow: nowrap row;
            align-items: center;

            i {
                margin-left: .25rem;
                color: $element;
            }
        }
    }

    .projects-container-profile-sub {
        width: 100%;

        margin-bottom: 2rem;
    }

    .info-literal {
        width: 100%;

        &>.info-all {
            width: 100%;
        }
    }
}

.activity-container {
    margin: 2rem 0;

    padding: 2rem 0;

    display: flex;
    flex-flow: nowrap column;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    h1,
    h2 {
        color: $element;
    }
}

.toolbar-profile {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1rem;

    @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: .5rem;
    }

    margin-bottom: 2rem;

    button, .button {
        background-color: white;
        border: 1px solid rgba(54, 54, 54, 0.2);
        padding: 0.25rem 1rem;
        width: 100%;

        font-weight: 500;
        color: rgb(54, 54, 54);

        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &:hover {
            background-color: $element;
            color: white;

            i {
                color: white;
            }
            button{
                color: white;
            }
        }

        i {
            margin: 0 0.5rem;
        }

        /*border-radius: 10rem;
        -webkit-border-radius: 10rem;
        -moz-border-radius: 10rem;
        -ms-border-radius: 10rem;
        -o-border-radius: 10rem;*/

        border-radius: .5rem;
        -webkit-border-radius: .5rem;
        -moz-border-radius: .5rem;
        -ms-border-radius: .5rem;
        -o-border-radius: .5rem;

        &.with-med{
            &::after{
                //counter-reset: p var(--points);
                //content: counter(p);
                /*content: attr(aria-points) '/50' ;

                display: inline-block;
                font-size: .5rem;
                color: black;

                z-index: 500;*/
            }
        }
    }


}



.projects-container-profile-sub {
    width: 100%;

    .activity-container {
        width: 100%;
        margin: 0;
        padding-top: 0 !important;
    }

    max-width: 80%;

    .cards-manager-projects {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        gap: 1rem;

        @media screen and (max-width: 900px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: 700px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

.info-user-side-right-points {
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-end;
    justify-content: flex-start;
}

@media screen and (max-width: $mobile) {
    .info-container {
        flex-flow: nowrap column !important;
        max-height: unset !important;
        height: unset;
        align-items: center !important;

        padding-top: 1rem;
    }
}

.control.expanded-com {
    display: none;
}

.profile-content {
    .edit-info {
        width: 1.5rem;
        height: 1.5rem;

        border-radius: 50%;

        background-color: var(--color);
        color: white;

        position: absolute;
        top: -0.75rem;
        right: -0.75rem;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        &:hover {
            color: var(--color);
            background-color: white;
        }
    }
}

.profile-edit {
    @include section;
    width: 100%;
}